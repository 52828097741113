import React, { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Loadable from "./layouts/full/shared/loadable/Loadable";
import Sample from "./Sample";
// import { useRoutes } from "react-router";
// const BlankLayout = Loadable(
//   lazy(() => import("../layouts/blank/BlankLayout"))
// );

const FullLayout = Loadable(lazy(() => import("./layouts/full/FullLayout")));
const Dashboard = Loadable(lazy(() => import("./views/dashboard/Dashboard")));
const Banner = Loadable(lazy(() => import("./views/banner/Banner")));
const Category = Loadable(lazy(() => import("./views/category/Category")));
const Company = Loadable(lazy(() => import("./views/company/Company")));
const Service = Loadable(lazy(() => import("./views/service/Service")));
const Testimonial = Loadable(
  lazy(() => import("./views/testimonial/Testimonial"))
);
const Error = Loadable(lazy(() => import("./views/authentication/Error")));
const Login = Loadable(lazy(() => import("./views/authentication/Login")));

function RoutesTwo() {
  return useRoutes([
    {
      path: "/",
      element: <FullLayout />,
      children: [
        { path: "dashboard", element: <Dashboard /> },
        { path: "banner", element: <Banner /> },
        { path: "category", element: <Category /> },
        { path: "company", element: <Company /> },
        { path: "service", element: <Service /> },
        { path: "testimonial", element: <Testimonial /> },
        { path: "/", element: <Navigate to="/dashboard" /> },
      ],
    },
    {
      path: "sample",
      element: <Sample />,
    },
    { path: "404", element: <Error /> },
    { path: "auth/login", element: <Login /> },
    { path: "*", element: <Navigate to="/404" /> },
  ]);
}

export default RoutesTwo;
